.root {
  width: 80%;
  max-width: unset !important;
}

.section {
  overflow: auto;
}

.activity {
  font-size: 16px;
  font-weight: 500;
}

.bookingTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  padding-top: 0px;
}

@media (min-width: 767px) {
  .headerContainer {
    padding: 16px 24px;
  }

  .avatar {
    width: 40px !important;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .section {
    margin: 20px 0;
  }
}
@media (max-width: 766px) {
  .headerContainer {
    padding: 12px 19px;
  }

  .modalPaperUpsell {
    height: 85vh !important;
    overflow-y: hidden !important;
  }
  .avatar {
    width: 32px !important;
    height: 32px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .mobileModalDivWrapper {
    padding-top: 16px;
    width: 93vw;
  }

  .section {
    margin: 15px 0;
  }
}
